import React, { version } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import { Box, Flex, Heading, Card } from 'rebass/styled-components'

import Layout from '../components/Layout'
import { LightSection } from '../components/Section'
import Container from '../components/Container'
import SectionTitle from '../components/SectionTitle'
import { draftjsContentToHTML } from '../utils/draftjsContentToHTML'
import { withI18next, Link } from 'gatsby-plugin-i18n'

const PresentationCategoryTemplate = ({ data, pageContext }) => {
  const { presentationCategory } = data
  const { language } = pageContext
  const { title, content, presentations } = presentationCategory
  const contentHTML = content ? draftjsContentToHTML(content) : null

  return (
    <Layout language={language}>
      <main>
        <LightSection>
          <Container maxWidth="60rem">
            <SectionTitle as="h1">{title}</SectionTitle>

            <div dangerouslySetInnerHTML={{ __html: contentHTML }} />

            {presentations && (
              <Flex justifyContent="space-between" flexWrap="wrap">
                {presentations.map((presentation) => {
                  if (!presentation) {
                    return null
                  }

                  const { image } = presentation

                  return (
                    <Card
                      key={presentation.id}
                      sx={{
                        mb: 4,
                        p: 3,
                        width: ['100%', '48%', '30%'],
                        bg: 'white',
                      }}
                    >
                      <Link
                        key={presentation.id}
                        to={`/presentation/${presentation.slug}`}
                        style={{
                          height: '100%',
                          textDecoration: 'none',
                        }}
                      >
                        <Flex
                          sx={{
                            height: '100%',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            textAlign: 'center',
                          }}
                        >
                          {image && image.local && (
                            <Box
                              sx={{
                                mb: 3,
                                height: '100%',
                              }}
                            >
                              <Img
                                fluid={image.local.childImageSharp.fluid}
                                alt={version.title}
                              />
                            </Box>
                          )}

                          <Heading
                            as="h2"
                            sx={{
                              mt: image ? 'auto' : '0',
                              justifySelf: 'center',
                            }}
                          >
                            {presentation.title}
                          </Heading>
                        </Flex>
                      </Link>
                    </Card>
                  )
                })}
              </Flex>
            )}
          </Container>
        </LightSection>
      </main>
    </Layout>
  )
}

PresentationCategoryTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export default withI18next()(PresentationCategoryTemplate)

export const pageQuery = graphql`
  query PresentationCategoryTemplate($id: String!, $language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { eq: "messages" } }
    ) {
      ...LocalLocaleFragment
    }
    presentationCategory: presentationCategories(id: { eq: $id }) {
      id
      title
      slug
      image {
        local {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 80) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      presentations {
        id
        title
        slug
        language
        image {
          local {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 80) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        versions {
          id
          title
        }
      }
    }
  }
`
